* {
  box-sizing: border-box;
}

body {
  margin: 0;
  background-color: white;
}
p{
  margin: 0;
  padding: 0;
}
