@import url(https://fonts.googleapis.com/css2?family=Open+Sans+Condensed:wght@300&family=Roboto&display=swap);
* {
  box-sizing: border-box;
}

body {
  margin: 0;
  background-color: white;
}
p{
  margin: 0;
  padding: 0;
}

header {
  background: white;
  padding-left: 1rem;
}

.header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}

.logo {
  margin-top: 1rem;
  -webkit-animation-duration: 2s;
          animation-duration: 2s;
  -webkit-animation-name: bounce;
          animation-name: bounce;
  -webkit-animation-iteration-count: 1;
          animation-iteration-count: 1;
}

@-webkit-keyframes bounce {
  from { padding-bottom: 50px; }
  to { padding-bottom: 0; }
}

@keyframes bounce {
  from { padding-bottom: 50px; }
  to { padding-bottom: 0; }
}

nav {
  display: none;
}

nav ul {
  display: flex;
  flex-direction: column;
  align-items: center;
  list-style: none;
  padding-left: 0;
}

nav ul li {
  text-align: center;
}

nav ul {
  list-style-type: none;
}

nav ul li {
  --c: #4caf50;

  border-radius: 0.5em;
  width: 10rem;
  height: 2em;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  text-align: center;
  line-height: 2em;
  position: relative;
  overflow: hidden;
  z-index: 1;
  transition: 0.5s;
  margin: 0.5em;
}

nav ul li span {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: var(--c);
  -webkit-transform: translateY(150%);
          transform: translateY(150%);
  border-radius: 50%;
  left: calc((var(--n) - 1) * 25%);
  transition: 0.5s;
  transition-delay: calc((var(--n) - 1) * 0.1s);
  z-index: -1;
}

nav ul li:hover {
  color: black;
}

nav ul li:hover span {
  -webkit-transform: translateY(0) scale(2);
          transform: translateY(0) scale(2);
}

nav ul li span:nth-child(1) {
  --n: 1;
}

nav ul li span:nth-child(2) {
  --n: 2;
}

nav ul li span:nth-child(3) {
  --n: 3;
}

nav ul li span:nth-child(4) {
  --n: 4;
}

.hamburger-opened nav {
  display: block;
  flex-basis: 100%;
}

.hamburger {
  cursor: pointer;
  height: 15px;
  margin: 0 20px 0 auto;
  position: relative;
  -webkit-transform: rotate(0deg);
  transform: rotate(0deg);
  width: 22px;
}

.hamburger-span {
  background: black;
  border-radius: 9px;
  cursor: pointer;
  display: block;
  height: 2px;
  left: 0;
  opacity: 1;
  position: absolute;
  -webkit-transform: rotate(0deg);
  transform: rotate(0deg);
  width: 100%;
  transition: 0.3s;
}

.hamburger-span:nth-child(2),
.hamburger-span:nth-child(3) {
  top: 8px;
}

.hamburger-span:nth-child(4) {
  top: 16px;
}

.hamburger-opened .hamburger-span:first-child,
.hamburger-opened .hamburger-span:last-child {
  opacity: 0;
}

.hamburger-opened .hamburger-span:nth-child(3) {
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

.hamburger-opened .hamburger-span:nth-child(2) {
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}

@media screen and (min-width: 700px) {
  .header-container {
    max-width: 1150px;
    margin: 0 auto;
    justify-content: space-between;
    align-items: center;
  }

  .hamburger {
    display: none;
  }

  nav {
    display: block;
    width: 50vw;
  }

  .hamburger-opened nav {
    flex-basis: auto;
  }

  nav ul {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-top: 1rem;
  }

  .navlink {
    width: 28%;
  }
}

@media screen and (min-width: 1020px) {
  .header-container {
    max-width: 90%;
    padding: 0;
    margin-left: 4%;
  }
}

nav a {
  text-decoration: none;
  color: black;
}

.footer {
  display: flex;
  text-align: center;
  align-items: center;
  flex-direction: column;
  margin-bottom: 0;
  background-color: #a3d6a4;
  bottom: 0;
  width: 100%;
  z-index: 1;
}

.social_list {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  padding: 0;
  width: 50vw;
  height: 50%;
}

.contactTeam {
  margin-top: 1rem;
  list-style: none;
  line-height: 1;
  font-size: 15px;
}

.contactTeam:hover {
  list-style: none;
  line-height: 1;
  font-size: 20px;
  transition-duration: 0.2s;
}

footer a {
  text-decoration: none;
  color: black;
}

@media screen and (min-width: 700px) {
  .social_list {
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
  }

  .footer {
    background-size: cover;
  }

  .contactTeam {
    padding: 0.5rem;
  }
}

footer p {
  color: rgb(69, 92, 39);
  margin-bottom: 2rem;
}

.mainSearch {
  background-image: url(/static/media/testbackground2.77ce9800.jpeg);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 40%;
  min-height: 71vh;
}

/* Correspond à la div globale de search */

.searchtext {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 10%;
  text-align: center;
}

.textSearch {
  color: #343434;
  font-size: 20px;
  padding: 0.2rem;
}

.buttonScan {
  border: medium none;
  top: 1rem;
  right: 1rem;
  position: absolute;
  z-index: 2;
  color: rgb(255, 81, 0);
}

/* bouton recherche */

.buttonadd {
  width: 18rem;
  height: 2.5rem;
  margin-left: 2%;
  font-weight: bold;
  color: white;
  border-radius: 10px;
  border: solid #4caf50 2px;
  font-weight: normal;
  background-color: #4caf50;
  transition-duration: 0.5s;
  box-shadow: rgba(0, 0, 0, 0.26) 5px 5px 10px;
  margin-top: 1rem;
  cursor: pointer;
}

.buttonScanOff {
  display: none;
}

video {
  top: 0;
  left: 0;
  background-color: black;
  height: 100%;
  width: 100%;
  position: absolute;
  z-index: 1;
}

.par2 {
  margin-top: 1rem;
  -webkit-text-decoration: double;
          text-decoration: double;
  font-style: italic;
  font-size: 15px;
  padding: 2px;
}

/* correspond à la div de recherche input + photo + add */

.search {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 3%;
}

.scandiv {
  display: flex;
  justify-content: space-around;
  height: 2.5rem;
  width: 18rem;
  background-color: white;
  margin-left: 1%;
  border: solid 1px rgba(48, 10, 10, 0.493);
  border-radius: 8px;
}

input.inputSearch {
  /* border: none; */
  margin-left : 1rem;
  width: 13rem;
  outline: none;
  border-radius: 14px;
  padding-left: 0;
  background-color: white;
  font-size: 0.8rem;
  font-style: italic;
  color: #343434;
  height: 100%;
  margin-bottom: 0;
}

.logoIconPhoto {
  width: 2rem;
  height: 2.1rem;
}

.buttonadd span {
  cursor: pointer;
  display: inline-block;
  position: relative;
  transition: 0.5s;
  font-size: 1rem;
}

.buttonadd span::after {
  position: absolute;
  opacity: 0;
  right: -20px;
  transition: 0.5s;
}

.buttonadd:hover {
  background-color: white;
  color: #4caf50;
}

.buttonadd:hover span::after {
  opacity: 1;
  right: 0;
}

@media screen and (min-width: 1020px) {
  .search {
    justify-content: center;
    flex-direction: row;
  }

  .textSearch {
    color: #343434;
    font-size: 32px;
  }

  .scandiv {
    width: 50rem;
    justify-content: space-between;
  }

  input.inputSearch {
    /* border: none; */
    width: 30rem;
    outline: none;
  }

  .logoIconPhoto {
    width: 2.3rem;
    height: 2.3rem;
    margin-right: 1rem;
  }

  .mainSearch {
    background-image: url(/static/media/testbackground2.77ce9800.jpeg);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: bottom;
  }

  .searchtext {
    padding-top: 10%;
  }

  .buttonadd {
    width: 16rem;
    height: 2rem;
    margin-left: 1rem;
    margin-bottom: 0.3rem;
    margin-top: 0;
  }

  .buttonadd span {
    font-size: 1rem;
  }
}


.ProductCard {
  background-size: cover;
  background-position: center;
  margin: 1rem;
  border-radius: 0.5rem;
  width: 18rem;
  background: white;
  box-shadow: rgba(0, 0, 0, 0.432) 5px 5px 10px;
  text-align: center;
}

.ProductCard-container {
  display: flex;
  flex-direction: column;
  padding: 1rem;
}

.ProductCard-container p {
  margin: 1rem;
}

.ProductCard-edit {
  display: flex;
  justify-content: space-between;
  padding-left: 1rem;
  padding-top: 1rem;
  padding-right: 1rem;
}

.ProductCard-edit svg {
  width: 30px;
  height: 30px;
}

.isFavorite {
  cursor: pointer;
  width: 30px;
  height: 30px;
  color: red;
  align-self: flex-start;
}

.notFavorite {
  cursor: pointer;
  width: 30px;
  height: 30px;
  color: #4caf50;
  opacity: 0.5;
  align-self: flex-start;
}

.notFavorite:hover{
  color: red;
}

.delete {
  cursor: pointer;
  width: 30px;
  height: 30px;
  color: grey;
  align-self: flex-start;
}

.delete :hover {
  background-color: #4caf50;
  color: white;
  border-radius: 60px;
}

.nustriscore {
  text-transform: uppercase;
  display: inline-block;
  padding: 0.3rem;
  background-color: black;
  color: white;
}

.nustriscore.a {
  background-color: green;
}

.nustriscore.b {
  background-color: green;
}

.details {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.details a {
  background-color: #4caf50;
  padding: 10px;
  margin-top: 0.8rem;
}

.details a:hover {
  box-shadow: 5px 5px 10px  grey;
  color: white;
}

.ProductCard a {
  text-decoration: none;
  color: white;
  border-radius: 10px;
}

.ProductCard-image {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.ProductCard-imagebackground:hover{
  box-shadow: 5px 5px 10px  grey;
}

.ProductCard-imagebackground {
  width: 6rem;
  height: 9rem;
  border-radius: 10px;
  background-position: 60%;
  background-size: cover;
  background-repeat: no-repeat;
  border: solid grey 1px;
}

.productName {
  text-transform: lowercase;
  color: #343434;
  font-style: normal;
  min-height: 3rem ;
}

.productName::first-letter {
  text-transform: capitalize;
}

.nutriscore.a {
  display: inline-block;
  width: 110px;
  height: 60px;
  background-image: url(/static/media/nutriscorea.80cb677b.png);
  background-size: contain;
  background-repeat: no-repeat;
}

.nutriscore.b {
  display: inline-block;
  width: 110px;
  height: 60px;
  background-image: url(/static/media/nutriscoreb.4041667b.png);
  background-size: contain;
  background-repeat: no-repeat;
}

.nutriscore.c {
  display: inline-block;
  width: 110px;
  height: 60px;
  background-image: url(/static/media/nutriscorec.02cad660.png);
  background-size: contain;
  background-repeat: no-repeat;
}

.nutriscore.d {
  display: inline-block;
  width: 110px;
  height: 60px;
  background-image: url(/static/media/nutriscored.8b2ce43d.png);
  background-size: contain;
  background-repeat: no-repeat;
}

.nutriscore.e {
  display: inline-block;
  width: 110px;
  height: 60px;
  background-image: url(/static/media/nutriscoree.d8832bb6.png);
  background-size: contain;
  background-repeat: no-repeat;
}
.averageglobal {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding-bottom: 2rem;
  padding-top: 1rem;
}

.averagediv {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 18rem;
  height: 4rem;
  padding: 1rem;
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.26) 5px 5px 10px;
  border-radius: 10px;
}

.scorepar {
  padding-right: 0.5rem;
  font-style: normal;
}

.average.A {
  background-image: url(/static/media/UmamiA.4bc5689d.png);
  width: 100%;
  height: 100%;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}
.average.B {
  background-image: url(/static/media/UmamiB.1274d3cb.png);
  width: 100%;
  height: 100%;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}
.average.C {
  background-image: url(/static/media/UmamiC.5058fc54.png);
  width: 100%;
  height: 100%;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}
.average.D {
  background-image: url(/static/media/UmamiD.48a733d9.png);
  width: 100%;
  height: 100%;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}
.average.E {
  background-image: url(/static/media/UmamiE.22ae0750.png);
  width: 100%;
  height: 100%;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.ProductList {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.ProductList_accueil {
  border: solid 1px black;
  text-align: center;
}

.ProductList_h3 {
  font-size: 2em;
}

.ProductList_p {
  font-size: 1.5em;

}

/* accueil card */
.accueilCard{
  margin: 1rem;
  width: 16rem;
  height: 20rem;
  background-color: rgba(229, 247, 229, 0.726);
  background-size: cover;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  border-radius: 10%;
  box-shadow: rgb(0 0 0 / 26%) 5px 5px 10px;
}

.accueilGlobal{
  display: flex;
  flex-direction: column;
  margin : 2rem;
}

@media screen and (min-width: 1024px) {
  .accueilGlobal{
    flex-direction: row;
  }
}
  

.accueilTitre{
  font-weight: bold;
  font-size: 1.4rem;
  text-align: center;
  width: 80%;
  padding: 2.5px;
  border-radius: 10px;
}
.imgAccueil{
  width: 6.5rem;
  height: 12.5rem;
}
.imgAccueil2{
  width: 18rem;
  height: 12.4rem;
}
.imgAccueil3{
  margin-top:1rem;
  width: 11rem;
  height: 12rem;
  border-radius: 10%;
}
.imgAccueil4{
  width: 9rem;
  height: 12.4rem;
  border-radius: 10%;
}




.ligne{
  color : white;
  width: 14rem;
  height: 2px;
}


.App {
  font-family: 'Dosis', sans-serif;
  height: 100vh;
  color: #343434;
  background-color: #a3d6a4;
}

.product {
  background: #e2f0f3;
}

.productIngredient {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  text-align: center;
  padding-bottom: 5%;
}

h3 {
  font-style: italic;
}

.ingredients {
  padding-left: 5%;
  padding-right: 5%;
}

.imageList {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  width: 100%;
}

.title {
  display: flex;
  flex-direction: column;
}

.product #favorite {
  align-self: center !important;
}

.product #favorite svg {
  width: 30px;
  height: 30px;
}

@media screen and (min-width: 400px) {
  .product {
    height: auto;
    padding-bottom: 5%;
  }

  .imageList {
    margin-top: 3%;
    padding-bottom: 0;
    width: 80%;
    display: flex;
    flex-direction: row;
    justify-content: center;
  }
}

.valeursnutri {
  text-align: center;
}

.titlelist {
  list-style-type: none;
  text-align: start;
  border-bottom: grey solid 1px;
  width: 100%;
}

.ullist {
  display: flex;
  flex-direction: column;
  margin: 0;
  padding: 0;
  margin-top: 5%;
  width: 40%;
  align-items: center;
}

/* .image {
  width: 40%;
  border: solid black 0.5px;
  box-shadow: 5px 5px 5px grey;
  border-radius: 10px;
  padding: 2%;
} */

.labeldiv {
  width: 100%;
}

@media screen and (min-width: 400px) {
  .image {
    width: 20%;
    margin-right: 10%;
  }

  .ullist {
    margin-top: 0;
    width: auto;
  }
}

/* label div en mobile a regler */

.ullabel {
  display: flex;
  justify-content: space-around;
  width: 100%;
  padding-top: 5%;
  -webkit-padding-start: 0;
          padding-inline-start: 0;
}

.label {
  /*
  border: solid black 0.5px;
  box-shadow: 5px 5px 5px grey;
  border-radius: 10px;
  width: 40%;
  */
  align-items: center;
  display: flex;
  flex-direction: column;
  list-style: none;
  padding: 1%;
  background: #fff;
}

@media screen and (min-width: 768px) {
  .label {
    width: 30%;
    flex-direction: row;
    justify-content: space-around;
    font-size: 20px;
  }

  .image {
    width: 20%;
  }

  .titlelist {
    font-size: 20px;
  }
}

@media screen and (min-width: 1024px) {
  .titlelist {
    font-size: 30px;
  }

  .label {
    font-size: 30px;
  }

  .productIngredient {
    padding-bottom: 0;
  }
}

/* icone details */

.saturatedFat.low {
  display: inline-block;
  width: 20px;
  height: 20px;
  background-image: url(/static/media/lowicon.9e452ee1.png);
  background-size: contain;
  background-repeat: no-repeat;
}

.saturatedFat.moderate {
  display: inline-block;
  width: 20px;
  height: 20px;
  background-image: url(/static/media/moderateicon.7451c3d0.png);
  background-size: contain;
  background-repeat: no-repeat;
}

.saturatedFat.high {
  display: inline-block;
  width: 20px;
  height: 20px;
  background-image: url(/static/media/highicon.55f39315.png);
  background-size: contain;
  background-repeat: no-repeat;
}

.salt.low {
  display: inline-block;
  width: 20px;
  height: 20px;
  background-image: url(/static/media/lowicon.9e452ee1.png);
  background-size: contain;
  background-repeat: no-repeat;
}

.salt.moderate {
  display: inline-block;
  width: 20px;
  height: 20px;
  background-image: url(/static/media/moderateicon.7451c3d0.png);
  background-size: contain;
  background-repeat: no-repeat;
}

.salt.high {
  display: inline-block;
  width: 20px;
  height: 20px;
  background-image: url(/static/media/highicon.55f39315.png);
  background-size: contain;
  background-repeat: no-repeat;
}

.sugars.low {
  display: inline-block;
  width: 20px;
  height: 20px;
  background-image: url(/static/media/lowicon.9e452ee1.png);
  background-size: contain;
  background-repeat: no-repeat;
}

.sugars.moderate {
  display: inline-block;
  width: 20px;
  height: 20px;
  background-image: url(/static/media/moderateicon.7451c3d0.png);
  background-size: contain;
  background-repeat: no-repeat;
}

.sugars.high {
  display: inline-block;
  width: 20px;
  height: 20px;
  background-image: url(/static/media/highicon.55f39315.png);
  background-size: contain;
  background-repeat: no-repeat;
}

.nova.nova1 {
  display: inline-block;
  width: 50px;
  height: 60px;
  background-image: url(/static/media/nova1.3ada227a.png);
  background-size: contain;
  background-repeat: no-repeat;
}

.nova.nova2 {
  display: inline-block;
  width: 50px;
  height: 60px;
  background-image: url(/static/media/nova2.4ee33f55.png);
  background-size: contain;
  background-repeat: no-repeat;
}

.nova.nova3 {
  display: inline-block;
  width: 50px;
  height: 60px;
  background-image: url(/static/media/nova3.ecac15eb.png);
  background-size: contain;
  background-repeat: no-repeat;
}

.nova.nova4 {
  display: inline-block;
  width: 35px;
  height: 60px;
  background-image: url(/static/media/nova4.d1d7192f.png);
  background-size: contain;
  background-repeat: no-repeat;
}

.nutriscore.a {
  display: inline-block;
  width: 110px;
  height: 60px;
  background-image: url(/static/media/nutriscorea.80cb677b.png);
  background-size: contain;
  background-repeat: no-repeat;
}

.nutriscore.b {
  display: inline-block;
  width: 110px;
  height: 60px;
  background-image: url(/static/media/nutriscoreb.4041667b.png);
  background-size: contain;
  background-repeat: no-repeat;
}

.nutriscore.c {
  display: inline-block;
  width: 110px;
  height: 60px;
  background-image: url(/static/media/nutriscorec.02cad660.png);
  background-size: contain;
  background-repeat: no-repeat;
}

.nutriscore.d {
  display: inline-block;
  width: 110px;
  height: 60px;
  background-image: url(/static/media/nutriscored.8b2ce43d.png);
  background-size: contain;
  background-repeat: no-repeat;
}

.nutriscore.e {
  display: inline-block;
  width: 110px;
  height: 60px;
  background-image: url(/static/media/nutriscoree.d8832bb6.png);
  background-size: contain;
  background-repeat: no-repeat;
}

@media screen and (min-width: 1024px) {
  .saturatedFat.low {
    width: 40px;
    height: 40px;
  }

  .saturatedFat.moderate {
    width: 40px;
    height: 40px;
  }

  .saturatedFat.high {
    width: 40px;
    height: 40px;
  }

  .salt.low {
    width: 40px;
    height: 40px;
  }

  .salt.moderate {
    width: 40px;
    height: 40px;
  }

  .salt.high {
    width: 40px;
    height: 40px;
  }

  .sugars.low {
    width: 40px;
    height: 40px;
  }

  .sugars.moderate {
    width: 40px;
    height: 40px;
  }

  .sugars.high {
    width: 40px;
    height: 40px;
  }

  .nova.nova1 {
    width: 35px;
    height: 60px;
  }

  .nova.nova2 {
    width: 35px;
    height: 60px;
  }

  .nova.nova3 {
    width: 35px;
    height: 60px;
  }

  .nova.nova4 {
    width: 35px;
    height: 60px;
  }

  .nutriscore.a {
    width: 110px;
    height: 60px;
  }

  .nutriscore.b {
    width: 110px;
    height: 60px;
  }

  .nutriscore.c {
    width: 110px;
    height: 60px;
  }

  .nutriscore.d {
    width: 110px;
    height: 60px;
  }

  .nutriscore.e {
    width: 110px;
    height: 60px;
  }
}

.textlevel {
  padding: 5%;
  text-align: center;
  width: 80%;
  font-style: oblique;
  color: grey;
}

.levelicon {
  margin-left: 2px;
  margin-right: 3px;
  width: 5%;
}

.lilevel {
  list-style: none;
  text-align: start;
}

@media screen and (min-width: 400px) {
  .textlevel {
    font-size: large;
    padding-top: 4%;
    padding-bottom: 0;
  }
}

@media screen and (min-width: 768px) {
  .textlevel {
    font-size: large;
    padding-top: 8%;
    padding-bottom: 0;
  }

  .levelicon {
    width: 20px;
  }
}

.MyFavorites {
  background-image: url(/static/media/testbackground2.77ce9800.jpeg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  min-height: 20rem ;
  text-align: center;
  padding: 2rem;
}

.MyFavorites-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}

.umamivide{
  display: flex;
  justify-content: center;
  padding: 1rem;
  border-radius: 10px;
}
.card{
  height: 3rem;
  width: 3rem;
  display:
}

.umamivide {
  background-color: white;
  max-width: 20rem;
}

.contact {
  background-image: url(/static/media/testbackground2.77ce9800.jpeg);
  background-size: content;
  background-repeat: no-repeat;
  background-position: 40%;
  display: flex;
  justify-content: center;
}

.contact-form {
  margin-top: 1.5rem;
}

@media screen and (min-width:600px) {
  .contact-form{
    width: 50%;
  }
}
.titlecontact {
  text-align: center;
  margin-top: 5px;
  font-size: 2rem;
}
.form-content {
  margin: 0 auto;
  width: 80%;
}
input,
textarea {
  background-color: white;
  font-size: 1.1rem;
  border: none;
  color:#343434;
  margin-bottom: 10px;
  padding: 10px;
  width: 100%;
  border-radius: 10px;
}
input::-webkit-input-placeholder, textarea::-webkit-input-placeholder {
 color: #343434;

}
input:-ms-input-placeholder, textarea:-ms-input-placeholder {
 color: #343434;

}
input::placeholder,
textarea::placeholder {
 color: #343434;

}

.email-content {
  position: relative;
}

#not-mail {
  display: none;
  position: absolute;
  top: 10px;
  color: #ff4d4d;
  right: 0;
  -webkit-transform-origin: 50% 50%;
          transform-origin: 50% 50%;
}
@-webkit-keyframes dongle {
  0% {
    -webkit-transform: translate(0px, 0px);
            transform: translate(0px, 0px);
  }
  10% {
    -webkit-transform: translate(-10px, 0px);
            transform: translate(-10px, 0px);
  }
  20% {
    -webkit-transform: translate(10px, 0px);
            transform: translate(10px, 0px);
  }
  30% {
    -webkit-transform: translate(-10px, 0px);
            transform: translate(-10px, 0px);
  }
  40% {
    -webkit-transform: translate(10px, 0px);
            transform: translate(10px, 0px);
  }
  50% {
    -webkit-transform: translate(-10px, 0px);
            transform: translate(-10px, 0px);
  }
  60% {
    -webkit-transform: translate(10px, 0px);
            transform: translate(10px, 0px);
  }
  70% {
    -webkit-transform: translate(-10px, 0px);
            transform: translate(-10px, 0px);
  }
  80% {
    -webkit-transform: translate(10px, 0px);
            transform: translate(10px, 0px);
  }
  90% {
    -webkit-transform: translate(-10px, 0px);
            transform: translate(-10px, 0px);
  }
  100% {
    -webkit-transform: translate(0px, 0px);
            transform: translate(0px, 0px);
  }
}
@keyframes dongle {
  0% {
    -webkit-transform: translate(0px, 0px);
            transform: translate(0px, 0px);
  }
  10% {
    -webkit-transform: translate(-10px, 0px);
            transform: translate(-10px, 0px);
  }
  20% {
    -webkit-transform: translate(10px, 0px);
            transform: translate(10px, 0px);
  }
  30% {
    -webkit-transform: translate(-10px, 0px);
            transform: translate(-10px, 0px);
  }
  40% {
    -webkit-transform: translate(10px, 0px);
            transform: translate(10px, 0px);
  }
  50% {
    -webkit-transform: translate(-10px, 0px);
            transform: translate(-10px, 0px);
  }
  60% {
    -webkit-transform: translate(10px, 0px);
            transform: translate(10px, 0px);
  }
  70% {
    -webkit-transform: translate(-10px, 0px);
            transform: translate(-10px, 0px);
  }
  80% {
    -webkit-transform: translate(10px, 0px);
            transform: translate(10px, 0px);
  }
  90% {
    -webkit-transform: translate(-10px, 0px);
            transform: translate(-10px, 0px);
  }
  100% {
    -webkit-transform: translate(0px, 0px);
            transform: translate(0px, 0px);
  }
}

.button {
  font-size: 1.3rem;
  margin-top: 15px;
  color: white;
  border-radius: 10px;
  border : solid #4caf50 2px;
  font-weight: normal;
  background-color: #4caf50;
  box-shadow: rgb(0 0 0 / 26%) 5px 5px 10px;
}
.button:hover {
  background-color: white;
  color: #4caf50;
  cursor: pointer;
}
.error::-webkit-input-placeholder {
  color: #ff4d4d;
}
.error:-ms-input-placeholder {
  color: #ff4d4d;
}
.error::placeholder {
  color: #ff4d4d;
}
.form-message {
  margin-top: 10px;
  padding: 12px;
  opacity: 0;
  transition: 0.2s ease;
  color: white;
  border-radius: 4px;
  box-shadow: 0 0 2px rgba(51, 51, 51, 0.3);
}
#message{
  min-height: 250px;
}

