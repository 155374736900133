.mainSearch {
  background-image: url('../../assets/testbackground2.jpeg');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 40%;
  min-height: 71vh;
}

/* Correspond à la div globale de search */

.searchtext {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 10%;
  text-align: center;
}

.textSearch {
  color: #343434;
  font-size: 20px;
  padding: 0.2rem;
}

.buttonScan {
  border: medium none;
  top: 1rem;
  right: 1rem;
  position: absolute;
  z-index: 2;
  color: rgb(255, 81, 0);
}

/* bouton recherche */

.buttonadd {
  width: 18rem;
  height: 2.5rem;
  margin-left: 2%;
  font-weight: bold;
  color: white;
  border-radius: 10px;
  border: solid #4caf50 2px;
  font-weight: normal;
  background-color: #4caf50;
  transition-duration: 0.5s;
  box-shadow: rgba(0, 0, 0, 0.26) 5px 5px 10px;
  margin-top: 1rem;
  cursor: pointer;
}

.buttonScanOff {
  display: none;
}

video {
  top: 0;
  left: 0;
  background-color: black;
  height: 100%;
  width: 100%;
  position: absolute;
  z-index: 1;
}

.par2 {
  margin-top: 1rem;
  text-decoration: double;
  font-style: italic;
  font-size: 15px;
  padding: 2px;
}

/* correspond à la div de recherche input + photo + add */

.search {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 3%;
}

.scandiv {
  display: flex;
  justify-content: space-around;
  height: 2.5rem;
  width: 18rem;
  background-color: white;
  margin-left: 1%;
  border: solid 1px rgba(48, 10, 10, 0.493);
  border-radius: 8px;
}

input.inputSearch {
  /* border: none; */
  margin-left : 1rem;
  width: 13rem;
  outline: none;
  border-radius: 14px;
  padding-left: 0;
  background-color: white;
  font-size: 0.8rem;
  font-style: italic;
  color: #343434;
  height: 100%;
  margin-bottom: 0;
}

.logoIconPhoto {
  width: 2rem;
  height: 2.1rem;
}

.buttonadd span {
  cursor: pointer;
  display: inline-block;
  position: relative;
  transition: 0.5s;
  font-size: 1rem;
}

.buttonadd span::after {
  position: absolute;
  opacity: 0;
  right: -20px;
  transition: 0.5s;
}

.buttonadd:hover {
  background-color: white;
  color: #4caf50;
}

.buttonadd:hover span::after {
  opacity: 1;
  right: 0;
}

@media screen and (min-width: 1020px) {
  .search {
    justify-content: center;
    flex-direction: row;
  }

  .textSearch {
    color: #343434;
    font-size: 32px;
  }

  .scandiv {
    width: 50rem;
    justify-content: space-between;
  }

  input.inputSearch {
    /* border: none; */
    width: 30rem;
    outline: none;
  }

  .logoIconPhoto {
    width: 2.3rem;
    height: 2.3rem;
    margin-right: 1rem;
  }

  .mainSearch {
    background-image: url('../../assets/testbackground2.jpeg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: bottom;
  }

  .searchtext {
    padding-top: 10%;
  }

  .buttonadd {
    width: 16rem;
    height: 2rem;
    margin-left: 1rem;
    margin-bottom: 0.3rem;
    margin-top: 0;
  }

  .buttonadd span {
    font-size: 1rem;
  }
}

