.contact {
  background-image: url('../../assets/testbackground2.jpeg');
  background-size: content;
  background-repeat: no-repeat;
  background-position: 40%;
  display: flex;
  justify-content: center;
}

.contact-form {
  margin-top: 1.5rem;
}

@media screen and (min-width:600px) {
  .contact-form{
    width: 50%;
  }
}
.titlecontact {
  text-align: center;
  margin-top: 5px;
  font-size: 2rem;
}
.form-content {
  margin: 0 auto;
  width: 80%;
}
input,
textarea {
  background-color: white;
  font-size: 1.1rem;
  border: none;
  color:#343434;
  margin-bottom: 10px;
  padding: 10px;
  width: 100%;
  border-radius: 10px;
}
input::placeholder,
textarea::placeholder {
 color: #343434;

}

.email-content {
  position: relative;
}

#not-mail {
  display: none;
  position: absolute;
  top: 10px;
  color: #ff4d4d;
  right: 0;
  transform-origin: 50% 50%;
}
@keyframes dongle {
  0% {
    transform: translate(0px, 0px);
  }
  10% {
    transform: translate(-10px, 0px);
  }
  20% {
    transform: translate(10px, 0px);
  }
  30% {
    transform: translate(-10px, 0px);
  }
  40% {
    transform: translate(10px, 0px);
  }
  50% {
    transform: translate(-10px, 0px);
  }
  60% {
    transform: translate(10px, 0px);
  }
  70% {
    transform: translate(-10px, 0px);
  }
  80% {
    transform: translate(10px, 0px);
  }
  90% {
    transform: translate(-10px, 0px);
  }
  100% {
    transform: translate(0px, 0px);
  }
}

.button {
  font-size: 1.3rem;
  margin-top: 15px;
  color: white;
  border-radius: 10px;
  border : solid #4caf50 2px;
  font-weight: normal;
  background-color: #4caf50;
  box-shadow: rgb(0 0 0 / 26%) 5px 5px 10px;
}
.button:hover {
  background-color: white;
  color: #4caf50;
  cursor: pointer;
}
.error::placeholder {
  color: #ff4d4d;
}
.form-message {
  margin-top: 10px;
  padding: 12px;
  opacity: 0;
  transition: 0.2s ease;
  color: white;
  border-radius: 4px;
  box-shadow: 0 0 2px rgba(51, 51, 51, 0.3);
}
#message{
  min-height: 250px;
}
